import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row, Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

function ContactusController(props) {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email_address: "",
        contact_number: "",
        message: "",
    });
    const [errors, setErrors] = useState({});

    const notifyMessage = (message) => {
        toast.success(message, {
            position: 'top-right', // You can choose the position
            autoClose: 3000,       // The time the notification is displayed (in milliseconds)
        });
    } 

    const handleClose = () => {
        setFormData({
            first_name: "",
            last_name: "",
            email_address: "",
            contact_number: "",
            message: "",
        });
        setErrors({});
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const isMobileValid = (contact_number) => {
        const mobileRegex = /^\d{10,10}$/; // Allows 8 to 15 digits
        return mobileRegex.test(contact_number);
    };

    const isEmailValid = (email_address) => {
        // Regular expression for email validation
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email_address);
    };
    

    const validateForm = () => {
        const newErrors = {};
        const spaceRegex = /\s/;
        if (formData.first_name.trim() === "") {
            newErrors.first_name = "First name is required.";
        }

        if(formData.first_name.includes(" ")){
            newErrors.first_name = "Space not allowed.";
        }

        if (formData.last_name.trim() === "") {
            newErrors.last_name = "Last name is required.";
        }

        if(formData.last_name.includes(" ")){
            newErrors.last_name = "Space not allowed.";
        }

        if (formData.email_address.trim() === "") {
            newErrors.email_address = "Email is required.";
        }else if(!isEmailValid(formData.email_address)){
            newErrors.email_address = "Email format is invalid.";
        }

        if (formData.contact_number.trim() === "") {
            newErrors.contact_number = "Phone number is required.";
        }else if (!isMobileValid(formData.contact_number)) {
            newErrors.contact_number = "Phone number is invalid.";
        }

        if (formData.message.trim() === "") {
            newErrors.message = "Message is required.";
        }else if (formData.message.length >= 1500) {
            newErrors.message = "Message should be less then 1500 character.";
        }

        // Add more validation rules for email and phone number as needed

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const newErrors = {};
            
            try {
                const response = await fetch(process.env.REACT_APP_BASE_API_URL+'/contact-form-submit', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(formData),
                });
                if (response.status == 200) {
                  // Form submitted successfully
                  notifyMessage('Contact form submitted successfully');
                  console.log('Contact form submitted successfully');
                } else {
                    const errorData = await response.json();
                    newErrors.errorMessage = errorData.dataArray.message;
                    setErrors(newErrors);
                    return Object.keys(newErrors).length === 0;
                }
            }catch (error) {
                newErrors.errorMessage = 'Contact form submission failed';
                setErrors(newErrors);
                return Object.keys(newErrors).length === 0;
            }
            // Submit the form data to your server or perform other actions
            handleClose();
        }
    };
    return ( 
        <main>
            <section className="banner_section">
                <Container>
                    <div className="page-title">
                        <h2>Contact Us</h2>
                        <p className="banner_des">By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.</p>
                    </div>
                </Container>
            </section>
            <section className="common-form-section ">
                <Container>    

                    <div className='contact_detail'>
                        <ul className="contact-us-links contact_page">
                        <li>
                            <p><span><i className="fas fa-location-dot"></i></span> Office No 901 Shri Gurukrupa Tower,<br/> 
Opposite HDFC Bank, <br/>
Rajkot, Gujarat, India</p>
                        </li>
                        <li>
                            <Link to=""><span><i className="fas fa-envelope"></i></span> support@emotionaltouchapp.com</Link>
                        </li>
                        <li>
                            <Link to=""><span><i className="fas fa-phone"></i></span> +91 8200660774</Link>
                        </li>                      
                        </ul>
                    </div>
                    {errors.errorMessage && errors.errorMessage.map((item, index) => (
                        <div><span className="text-danger">{item}</span></div>
                    ))}
                    <Form className="contact_us_form" onSubmit={handleSubmit}>
                                <Row>
                                                                   
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" name="first_name" className="form-rounded" placeholder='Enter first name'  value={formData.first_name} onChange={handleInputChange}></Form.Control>
                                            {errors.first_name && <span className="text-danger">{errors.first_name}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" name="last_name" className="form-rounded" placeholder='Enter last name' value={formData.last_name} onChange={handleInputChange}></Form.Control>
                                            {errors.last_name && <span className="text-danger">{errors.last_name}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control type="email" name="email_address" className="form-rounded" placeholder='Enter email address' value={formData.email_address}  onChange={handleInputChange}></Form.Control>
                                            {errors.email_address && <span className="text-danger">{errors.email_address}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Mobile Number</Form.Label>                                            
                                            <InputGroup className="input-number">
                                                <Form.Select aria-label="country code" className="form-rounded select-tag">
                                                    <option>+ 91</option>
                                                </Form.Select>
                                                <Form.Control type="text" className="form-rounded" name="contact_number" placeholder='Enter phone number'  value={formData.contact_number}  onChange={handleInputChange}></Form.Control>
                                            </InputGroup>
                                            {errors.contact_number && <span className="text-danger">{errors.contact_number}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control as="textarea" className="form-rounded" name="message" rows={4} placeholder='Your message...' value={formData.message}  onChange={handleInputChange}></Form.Control>
                                            {errors.message && <span className="text-danger">{errors.message}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3 text-center">
                                            <Button type="submit" variant="primary" className="btn-rounded btn-wh-200-45">Submit</Button>
                                        </Form.Group>
                                    </Col>
                                </Row>         
                    </Form> 
                </Container>
            </section>
        </main>   
    );
}

export default ContactusController;