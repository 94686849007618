
import React, { Component } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { appRoutes} from "./Services/Routes";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import PageLoader from "./Services/PageLoader";
import { swiper } from 'swiper';

import "bootstrap/dist/css/bootstrap.min.css";
import "./Assests/fonts/font-awesome-pro-v6/css/all.min.css";
import "./Assests/fonts/Nunito/stylesheet.css";
import "./Assests/css/designer.css";

import "./Assests/js/theme.js";

const AppLayout = React.lazy(() => import("./Layouts/AppLayout"));

class App extends Component{
render() { 
  return (
    <BrowserRouter>
         <ToastContainer />
        {appRoutes.map((route, idx) => {
          return route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={(props) => <AppLayout {...props} />}
            />
          ) : null;
        })}
    </BrowserRouter>    
  );
}
}

export default App;
