// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html{
    scroll-padding-top: 100px;
}
.page-title h2 {
    color: #DC2C1D;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}
.banner_section {
    background-color: #1d1c1c;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact_us_form {
    padding: 30px;
    border: 1px solid #777777;
    border-radius: 20px;
}
.contact_us_form .form-control{
    background-color: #323232;
    border: 1px solid #777777;
}
.contact_us_form .form-control::placeholder{
    color: #d3d3d3;
}
.contact_detail ul.contact-us-links {
    list-style: none;
    padding-left: 0;
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
}
.contact_us_form .form-control{
    color: #ffffff;
}
.terbs_box {
    margin-bottom: 20px;
}
.terbs_box .tb_title {
    font-size: 22px;
    color: #ffffff;
}
.terbs_box .tb_para {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 7px;
}
.contact_us_form textarea.form-control{
    padding: 15px 20px;
}
.banner_des{
    font-size: 18px;
    color: #ffffff;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Assests/css/pages.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;AACA;IACI,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;AACvB;AACA;IACI,cAAc;AAClB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,cAAc;AAClB;AACA;IACI,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB","sourcesContent":["html{\n    scroll-padding-top: 100px;\n}\n.page-title h2 {\n    color: #DC2C1D;\n    font-size: 40px;\n    font-weight: 600;\n    text-align: center;\n}\n.banner_section {\n    background-color: #1d1c1c;\n    height: 250px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.contact_us_form {\n    padding: 30px;\n    border: 1px solid #777777;\n    border-radius: 20px;\n}\n.contact_us_form .form-control{\n    background-color: #323232;\n    border: 1px solid #777777;\n}\n.contact_us_form .form-control::placeholder{\n    color: #d3d3d3;\n}\n.contact_detail ul.contact-us-links {\n    list-style: none;\n    padding-left: 0;\n    display: flex;\n    justify-content: space-around;\n    margin-bottom: 50px;\n}\n.contact_us_form .form-control{\n    color: #ffffff;\n}\n.terbs_box {\n    margin-bottom: 20px;\n}\n.terbs_box .tb_title {\n    font-size: 22px;\n    color: #ffffff;\n}\n.terbs_box .tb_para {\n    font-size: 16px;\n    color: #ffffff;\n    margin-bottom: 7px;\n}\n.contact_us_form textarea.form-control{\n    padding: 15px 20px;\n}\n.banner_des{\n    font-size: 18px;\n    color: #ffffff;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
