import React from 'react';
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row, Form, InputGroup } from 'react-bootstrap';

function SignupController(props) {

    return ( 
        <main>
            <section className="common-form-section">
                <Container>                    
                    <Card className="card-dark mw-600 mx-auto br-20">
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="w-100">Profile Of User/Listener</Form.Label>                                        
                                            <Form.Check
                                                type="radio"
                                                label="User"
                                                name="ProfileType"
                                                id="ProfileTypeUser"                                                
                                                className="d-inline-block mb-2 me-4"
                                                checked
                                            />
                                            <Form.Check
                                                type="radio"
                                                label="Listener"
                                                name="ProfileType"
                                                id="ProfileTypeListener"
                                                className="d-inline-block mb-2"
                                            />
                                        </Form.Group>
                                    </Col>                                    
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" className="form-rounded" placeholder='Enter first name'></Form.Control>
                                        </Form.Group>                           
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" className="form-rounded" placeholder='Enter last name'></Form.Control>
                                        </Form.Group>                           
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control type="email" className="form-rounded" placeholder='Enter email address'></Form.Control>
                                        </Form.Group>                           
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Phone Number</Form.Label>                                            
                                            <InputGroup className="input-number">
                                                <Form.Select aria-label="country code" className="form-rounded">
                                                    <option>+ 91</option>
                                                    <option>+ 1</option>
                                                    <option>+ 123</option>
                                                    <option>+ 2</option>
                                                </Form.Select>
                                                <Form.Control type="text" className="form-rounded" placeholder='Enter phone number'></Form.Control>
                                            </InputGroup>
                                        </Form.Group>                           
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control as="textarea" className="form-rounded" rows={4} placeholder='Your message...'></Form.Control>
                                        </Form.Group>                           
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3 text-center">
                                            <Button type="submit" variant="primary" className="btn-rounded btn-wh-200-45">Sign in</Button>
                                        </Form.Group>
                                    </Col>
                                </Row>         
                            </Form>
                        </Card.Body>
                    </Card>      
                </Container>
            </section>
        </main>   
    );
}

export default SignupController;