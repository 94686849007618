import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Button, Card, Col, Container, Row, Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';

function EmotionaltouchController(props) {
    const history = useHistory();
    const { urlId } = useParams();
 
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        full_name: "",
        phone_number: "",
        email: "",
        dob: "",
        gender: "",
        question_1: "",
        question_2: "",
        question_3: "",
        question_4: "",
        question_5: "",
        resume: "",
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BASE_API_URL + `/get-user-data?urlId=${urlId}`);
                if (response.status === 200) {
                    const responseData = await response.json();
                    // Set your form data based on the API response if needed
                    const phoneNumberWithoutCode = responseData.data.user_details.phone_number.replace(/^(\+91)/, '');
                    setFormData({
                        full_name: responseData.data.user_details.full_name,
                        phone_number: phoneNumberWithoutCode,
                        email: responseData.data.user_details.email,
                        dob: "",
                        gender: "",
                        question_1: "",
                        question_2: "",
                        question_3: "",
                        question_4: "",
                        question_5: "",
                        resume: "",
                    });
                } else {
                    // Handle error, for example, redirect to an error page
                    console.error('API call failed');
                   history.push('/');
                }
            } catch (error) {
                // Handle network error or other exceptions
                console.error('Error in API call:', error);
                history.push('/');
            }
        };

        fetchData();
    }, [urlId]);

    const notifyMessage = (message) => {
        toast.success(message, {
            position: 'top-right', // You can choose the position
            autoClose: 3000,       // The time the notification is displayed (in milliseconds)
        });
    } 

    const handleClose = () => {
        setFormData({
            full_name: "",
            phone_number: "",
            email: "",
            dob: "",
            gender: "",
            question_1: "",
            question_2: "",
            question_3: "",
            question_4: "",
            question_5: "",
            resume: "",
        });
        setErrors({});
    };

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        const file = type === "file" ? files[0] : null;
        setFormData({
            ...formData,
            [name]: type === "file" ? file : value,
        });
    };

    const isMobileValid = (phone_number) => {
        const mobileRegex = /^\d{10,10}$/; // Allows 8 to 15 digits
        return mobileRegex.test(phone_number);
    };

    const isEmailValid = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };
    

    const validateForm = () => {
        const newErrors = {};
        const spaceRegex = /\s/;
        if (formData.full_name.trim() === "") {
            newErrors.full_name = "Fullname is required.";
        }

        if (formData.email.trim() === "") {
            newErrors.email = "Email is required.";
        }else if(!isEmailValid(formData.email)){
            newErrors.email = "Email format is invalid.";
        }

        if (formData.phone_number.trim() === "") {
            newErrors.phone_number = "Phone number is required.";
        }else if (!isMobileValid(formData.phone_number)) {
            newErrors.phone_number = "Phone number is invalid.";
        }

        if (formData.dob.trim() === "") {
            newErrors.dob = "Date of Birth is required.";
        } else {
            // Calculate age based on the current date
            const currentDate = new Date();
            const selectedDate = new Date(formData.dob);
    
            const ageDiff = currentDate.getFullYear() - selectedDate.getFullYear();
            const isUnderage = ageDiff < 18 || (ageDiff === 18 && currentDate.getMonth() < selectedDate.getMonth());
    
            if (isUnderage) {
                newErrors.dob = "Age must be at least 18 years old.";
            }
        }

        if (formData.gender.trim() === "") {
            newErrors.gender = "Gender is required.";
        }

        if (formData.question_1.trim() === "") {
            newErrors.question_1 = "This question is required.";
        }else if (formData.question_1.length >= 4000) {
            newErrors.question_1 = "Answer should be less then 4000 character.";
        }

        if (formData.question_2.trim() === "") {
            newErrors.question_2 = "This question is required.";
        }else if (formData.question_2.length >= 4000) {
            newErrors.question_2 = "Answer should be less then 4000 character.";
        }

        if (formData.question_3.trim() === "") {
            newErrors.question_3 = "This question is required.";
        }else if (formData.question_3.length >= 4000) {
            newErrors.question_3 = "Answer should be less then 4000 character.";
        }

        if (formData.question_4.trim() === "") {
            newErrors.question_4 = "This question is required.";
        }else if (formData.question_4.length >= 4000) {
            newErrors.question_4 = "Answer should be less then 4000 character.";
        }

        if (formData.question_5.trim() === "") {
            newErrors.question_5 = "This question is required.";
        }else if (formData.question_5.length >= 4000) {
            newErrors.question_5 = "Answer should be less then 4000 character.";
        }

        if (!formData.resume) {
            newErrors.resume = "Resume is required.";
        } else {
            const allowedFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            const maxFileSize = 5 * 1024 * 1024; // 5 MB
    
            if (!allowedFileTypes.includes(formData.resume.type)) {
                newErrors.resume = "File type not allowed. Please upload a PDF or DOC/DOCX file.";
            } else if (formData.resume.size > maxFileSize) {
                newErrors.resume = "File size exceeds the maximum limit of 5 MB.";
            }
        }
        // Add more validation rules for email and phone number as needed

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const newErrors = {};
            console.log(formData.resume);

            console.log(JSON.stringify(formData));
      
            let formDataNew = new FormData();
            formDataNew.append("dob", formData.dob ?? "");
            formDataNew.append("email", formData.email ?? "");
            formDataNew.append("full_name", formData.full_name ?? "");
            formDataNew.append("gender", formData.gender);
            formDataNew.append("phone_number", formData.phone_number);
            formDataNew.append("resume", formData.resume);
            formDataNew.append("question_1", formData.question_1);
            formDataNew.append("question_2", formData.question_2);
            formDataNew.append("question_3", formData.question_3);
            formDataNew.append("question_4", formData.question_4);
            formDataNew.append("question_5", formData.question_5);
            console.log('formDataNew: ',formDataNew);
            
            try {
                axios.post(process.env.REACT_APP_BASE_API_URL+'/google-docs-webhook', formDataNew, {
                    headers: {
                        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
                    }
                })
                .then(response => {
                    if (response.status == 200) {
                      // Form submitted successfully
                      localStorage.setItem('successMessage', 'Form submitted successfully');
                    // Reload the page
                    window.location.reload();
                    //   notifyMessage('Form submitted successfully');
                    //   setTimeout(() => {
                    //     window.location.reload();
                    //   }, 3000);
                      
                    } else {
                      notifyMessage('Something went wrong');
                    } 
                })
                /* const response = await fetch(process.env.REACT_APP_BASE_API_URL+'/google-docs-webhook', {
                  method: 'POST',
                  headers: {
                    "Accept": "application/json",
                    "Content-Type": "multipart/form-data;",
                  },
                  body: formDataNew,
                });
                console.log('hiihi',response);
                if (response.status == 200) {
                  // Form submitted successfully
                  notifyMessage('Form submitted successfully');
                  console.log('Form submitted successfully');
                } else {
                    const errorData = await response.json();
                    newErrors.errorMessage = errorData.dataArray.message;
                    setErrors(newErrors);
                    return Object.keys(newErrors).length === 0;
                } */
            }catch (error) {
                newErrors.errorMessage = 'Form submission failed';
                setErrors(newErrors);
                return Object.keys(newErrors).length === 0;
            }
            // Submit the form data to your server or perform other actions
            handleClose();
        }
    };
    return ( 
        <main>
            <section className="banner_section">
                <Container>
                    <div className="page-title">
                        <h2>Emotional Touch</h2>
                        
                    </div>
                </Container>
            </section>
            <section className="common-form-section ">
                <Container>    
                 
                    <Form encType="multipart/form-data" className="emotional_touch_form" onSubmit={handleSubmit}>
                                <Row>                           
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control type="text" name="full_name" readOnly className="form-rounded" placeholder='Full Name' value={formData.full_name} onChange={handleInputChange}></Form.Control>
                                            {errors.full_name && <span className="text-danger">{errors.full_name}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Contact Number</Form.Label>                                            
                                            <InputGroup className="input-number">
                                                <Form.Select aria-label="country code" className="form-rounded select-tag">
                                                    <option>+ 91</option>
                                                </Form.Select>
                                                <Form.Control type="text" className="form-rounded" readOnly name="phone_number" placeholder='Enter phone number' value={formData.phone_number} onChange={handleInputChange}></Form.Control>
                                            </InputGroup>
                                            {errors.phone_number && <span className="text-danger">{errors.phone_number}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control type="email" name="email" readOnly className="form-rounded" placeholder='Enter email address' value={formData.email} onChange={handleInputChange}></Form.Control>
                                            {errors.email && <span className="text-danger">{errors.email}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <Form.Control type="date" name="dob" className="form-rounded" placeholder='Enter email address' value={formData.dob} onChange={handleInputChange}></Form.Control>
                                            {errors.dob && <span className="text-danger">{errors.dob}</span>}
                                        </Form.Group>                         
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Gender</Form.Label>
                                                <div className="d-flex gap-3">
                                                    <Form.Check type="radio" id="male" label="Male" name="gender" value="male" onChange={handleInputChange}/>
                                                    <Form.Check type="radio" id="female" label="Female" name="gender" value="female"  onChange={handleInputChange}/>
                                                </div>
                                                {errors.gender && <span className="text-danger">{errors.gender}</span>}
                                        </Form.Group>                         
                                    </Col>
                                    
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Tell us about yourself?<span>*</span>Tell Something about yourself, a short bio.</Form.Label>
                                            <Form.Control as="textarea" className="form-rounded" name="question_1" rows={1} placeholder='Your message...' value={formData.question_1}  onChange={handleInputChange}></Form.Control>
                                            {errors.question_1 && <span className="text-danger">{errors.question_1}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>How do others describe you?</Form.Label>
                                            <Form.Control as="textarea" className="form-rounded" name="question_2" rows={1} placeholder='Your message...' value={formData.question_2}  onChange={handleInputChange}></Form.Control>
                                            {errors.question_2 && <span className="text-danger">{errors.question_2}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>What would you do if a customer was being unnecessarily rude to you?*</Form.Label>
                                            <Form.Control as="textarea" className="form-rounded" name="question_3" rows={1} placeholder='Your message...' value={formData.question_3}  onChange={handleInputChange}></Form.Control>
                                            {errors.question_3 && <span className="text-danger">{errors.question_3}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Why do you want to work with Emotional Touch?*</Form.Label>
                                            <Form.Control as="textarea" className="form-rounded" name="question_4" rows={1} placeholder='Your message...' value={formData.question_4}  onChange={handleInputChange}></Form.Control>
                                            {errors.question_4 && <span className="text-danger">{errors.question_4}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>How did you hear about this position?*</Form.Label>
                                            <Form.Control as="textarea" className="form-rounded" name="question_5" rows={1} placeholder='Your message...' value={formData.question_5}  onChange={handleInputChange}> </Form.Control>
                                            {errors.question_5 && <span className="text-danger">{errors.question_5}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Please upload your resume<span>*</span></Form.Label>
                                            <Form.Control type="file" className="form-rounded file_upload" name="resume" onChange={handleInputChange}/>
                                            {errors.resume && <span className="text-danger">{errors.resume}</span>}
                                        </Form.Group>                           
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3 text-center">
                                            <Button type="submit" variant="primary" className="btn-rounded btn-wh-200-45">Submit</Button>
                                        </Form.Group>
                                    </Col>
                                </Row>         
                    </Form> 
                </Container>
            </section>
        </main>   
    );
}


export default EmotionaltouchController;