// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./component.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./header.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./common-style.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./homepage.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./responsive.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./footer.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./pages.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*-------------------------------------------
            Global Variables  
--------------------------------------------*/
:root {
  --colorBlack: #000000;
  --colorDark: #1D1C1C;
  --colorWhite: #ffffff;
  --colorPrimary: #DC2C1D;
  --colorSecondary: #2E2E2E;
  --colorSuccess: #2CBA44;
  --colorYellow: #FAC247;
  --colorGray: #CFCFCF;
  --colorGrayLight: #777777;
  --colorGrayDark: #6F6F70;
  --colorLight: #434242;

  --btnPrimary: #DC2C1D;
  --btnSecondary: #2E2E2E;
  --btnSuccess: #2CBA44;
  --btnDanger: #d03e50;
  --fullwidth: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Assests/css/designer.css"],"names":[],"mappings":"AAAA;;6CAE6C;AAC7C;EACE,qBAAqB;EACrB,oBAAoB;EACpB,qBAAqB;EACrB,uBAAuB;EACvB,yBAAyB;EACzB,uBAAuB;EACvB,sBAAsB;EACtB,oBAAoB;EACpB,yBAAyB;EACzB,wBAAwB;EACxB,qBAAqB;;EAErB,qBAAqB;EACrB,uBAAuB;EACvB,qBAAqB;EACrB,oBAAoB;EACpB,iBAAiB;AACnB","sourcesContent":["/*-------------------------------------------\n            Global Variables  \n--------------------------------------------*/\n:root {\n  --colorBlack: #000000;\n  --colorDark: #1D1C1C;\n  --colorWhite: #ffffff;\n  --colorPrimary: #DC2C1D;\n  --colorSecondary: #2E2E2E;\n  --colorSuccess: #2CBA44;\n  --colorYellow: #FAC247;\n  --colorGray: #CFCFCF;\n  --colorGrayLight: #777777;\n  --colorGrayDark: #6F6F70;\n  --colorLight: #434242;\n\n  --btnPrimary: #DC2C1D;\n  --btnSecondary: #2E2E2E;\n  --btnSuccess: #2CBA44;\n  --btnDanger: #d03e50;\n  --fullwidth: 100%;\n}\n\n@import url(\"component.css\");\n@import url(\"header.css\");\n@import url(\"common-style.css\");\n@import url(\"homepage.css\");\n@import url(\"responsive.css\");\n@import url(\"footer.css\");\n@import url(\"pages.css\");\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
