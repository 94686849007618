import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row, Form, InputGroup } from 'react-bootstrap';

function RefundPolicyController(props) {
    const [refundData, setPrivacy] = useState({
        title: "",
        description: "",
    });

    useEffect(() => {
      // Function to fetch terms from the backend
      const fetchPrivacy = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_BASE_API_URL+'/get-pages-data?page_id=4');
          const data = await response.json();
          setPrivacy({
                title: data.data.page_details.title,
                description: data.data.page_details.description,
            });
            // Assuming your API response has a 'terms' field
        } catch (error) {
          console.error('Error fetching terms:', error);
        }
      };
  
      // Call the fetchPrivacy function
      fetchPrivacy();
    }, []); // Empty dependency array means this effect runs once after the initial render
  
    return ( 
        <main>
        <section className="banner_section">
            <Container>
                <div className="page-title">
                    <h2>{refundData.title}</h2>
                </div>
            </Container>
        </section>
        <section className="terms_condi_page ">
            <Container>    
                <div className="terbs_box" dangerouslySetInnerHTML={{ __html: refundData.description }} />
            </Container>
        </section>
    </main>    
    );
}

export default RefundPolicyController;