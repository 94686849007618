import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row, Form, InputGroup } from 'react-bootstrap';

function TermsconditionController(props) {
    const [termData, setTerms] = useState({
        title: "",
        description: "",
    });

    useEffect(() => {
      // Function to fetch terms from the backend
      const fetchTerms = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_BASE_API_URL+'/get-pages-data?page_id=1');
          const data = await response.json();
          console.log(data.data.page_details.description);
          setTerms({
                title: data.data.page_details.title,
                description: data.data.page_details.description,
            });
            // Assuming your API response has a 'terms' field
        } catch (error) {
          console.error('Error fetching terms:', error);
        }
      };
  
      // Call the fetchTerms function
      fetchTerms();
    }, []);

    return ( 
        <main>
        <section className="banner_section">
            <Container>
                <div className="page-title">
                    <h2>{termData.title}</h2>
                </div>
            </Container>
        </section>
        <section className="terms_condi_page ">
            <Container>    
                <div className="terbs_box" dangerouslySetInnerHTML={{ __html: termData.description }} />
            </Container>
        </section>
    </main>    
    );
}

export default TermsconditionController;