import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row, Form, Accordion, InputGroup, Modal } from 'react-bootstrap';
import 'swiper/swiper-bundle.css'; 
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Rating from 'react-rating';
import { toast } from 'react-toastify';



function HomePageController(props) {
    const [show, setShow] = useState(false);
    //const handleClose = () => setShow(false);
    ///const handleShow = () => setShow(true);

    const [aboutData, setAboutData] = useState({
        title: "",
        description: "",
    });

    const [faqsData, setFaqsData] = useState({
        faqs_datails: [],
    });

    const [listenerData, setListenerData] = useState({
        listener_data: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
              const aboutResponse = await fetch(process.env.REACT_APP_BASE_API_URL + '/get-pages-data?page_id=3');
              const aboutData = await aboutResponse.json();
              const aboutImgPath = aboutData.data.page_details.image.replace('https:/', 'https://');
              setAboutData({
                title: aboutData.data.page_details.title,
                description: aboutData.data.page_details.description,
                image: aboutImgPath,
              });
        
              const faqsResponse = await fetch(process.env.REACT_APP_BASE_API_URL + '/get-faqs-data');
              const faqsData = await faqsResponse.json();
              setFaqsData({
                faqs_datails: faqsData.data.faqs_details,
              });


              const listenerResponse = await fetch(process.env.REACT_APP_BASE_API_URL + '/get-front-listener-data');
              const listenerData = await listenerResponse.json();
              setListenerData({
                listener_data: listenerData.data.listener_data,
              });
        
             
            } catch (error) {
              console.error('Error fetching data:', error);
              // Handle errors, e.g., set an error state
              
            }
          };
        
          fetchData();
        const successMessage = localStorage.getItem('successMessage');
        if (successMessage) {
            notifyMessage(successMessage);
              // Clear the success message from localStorage after displaying it
            localStorage.removeItem('successMessage');
        }
    }, []); // Empty dependency array means this effect runs once after the initial render

   
    const [formData, setFormData] = useState({
        FullName: "",
        email: "",
        phoneNumber: "",
        message: "",
    });
    const [errors, setErrors] = useState({});

    const notifyMessage = (message) => {
        toast.success(message, {
            position: 'top-right', // You can choose the position
            autoClose: 3000,       // The time the notification is displayed (in milliseconds)
        });
    } 

    const handleClose = () => {
        setShow(false);
        setFormData({
            FullName: "",
            email: "",
            phoneNumber: "",
            message: "",
        });
        setErrors({});
    };

    const handleShow = () => setShow(true);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const isMobileValid = (phoneNumber) => {
        const mobileRegex = /^\d{10,10}$/; // Allows 8 to 15 digits
        return mobileRegex.test(phoneNumber);
    };

    const isEmailValid = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };
    

    const validateForm = () => {
        const newErrors = {};

        if (formData.FullName.trim() === "") {
            newErrors.FullName = "Full name is required.";
        }

        if (formData.email.trim() === "") {
            newErrors.email = "Email is required.";
        }else if(!isEmailValid(formData.email)){
            newErrors.email = "Email format is invalid.";
        }

        if (formData.phoneNumber.trim() === "") {
            newErrors.phoneNumber = "Phone number is required.";
        }else if (!isMobileValid(formData.phoneNumber)) {
            newErrors.phoneNumber = "Phone number is invalid.";
        }

        if (formData.message.trim() === "") {
            newErrors.message = "Message is required.";
        }else if (formData.message.length >= 1500) {
            newErrors.message = "Message should be less then 1500 character.";
        }

        // Add more validation rules for email and phone number as needed

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const newErrors = {};
            
            try {
                const response = await fetch(process.env.REACT_APP_BASE_API_URL+'/listner-form-submit', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(formData),
                });
                if (response.status == 200) {
                  // Form submitted successfully
                  notifyMessage('Form submitted successfully');
                  console.log('Form submitted successfully');
                } else {
                    const errorData = await response.json();
                    newErrors.errorMessage = errorData.dataArray.message;
                    setErrors(newErrors);
                    return Object.keys(newErrors).length === 0;
                }
            }catch (error) {
                newErrors.errorMessage = 'Form submission failed';
                setErrors(newErrors);
                return Object.keys(newErrors).length === 0;
            }
            // Submit the form data to your server or perform other actions
            handleClose();
        }
    };

    return (
        <main>
            <section className="banner-section">
                <Container>
                    <div className="banner-content">
                        <h1>Feeling <b>Broken</b></h1>
                        <p>Get Emotional Support on anonymous <br></br><span>Chat, Call or Video Call</span></p>
                        {/* <Button type="button" onClick={handleShow} variant="primary" className="btn-rounded btn-wh-126-45">Register</Button> */}
                    </div>
                    <div className="banner-img">
                        <img alt="banner-image"
                            src={require("../Assests/images/banner-image-01.png")}
                        />
                    </div>
                    <Modal
                        centered
                        size="md"
                        show={show}
                        onHide={handleClose}
                        className="modal-dark"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Register Yourself As A Listener</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {errors.errorMessage && errors.errorMessage.map((item, index) => (
                                <div><span className="text-danger">{item}</span></div>
                            ))}
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg={12} md={12} sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-rounded"
                                                placeholder="Enter full name"
                                                name="FullName"
                                                value={formData.FullName}
                                                onChange={handleInputChange}
                                            />
                                            {errors.FullName && <span className="text-danger">{errors.FullName}</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-rounded"
                                                placeholder="Enter email address"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                            {errors.email && <span className="text-danger">{errors.email}</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Phone Number</Form.Label>
                                            <InputGroup className="input-number">
                                                <Form.Select aria-label="country code" className="form-rounded">
                                                    <option value="+91">+ 91</option>
                                                </Form.Select>
                                                <Form.Control
                                                    type="text"
                                                    className="form-rounded"
                                                    placeholder="Enter phone number"
                                                    name="phoneNumber"
                                                    value={formData.phoneNumber}
                                                    onChange={handleInputChange}
                                                />
                                            </InputGroup>
                                            {errors.phoneNumber && <span className="text-danger">{errors.phoneNumber}</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-lg-4 mb-3">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                className="form-rounded"
                                                rows={4}
                                                placeholder="Your message..."
                                                name="message"
                                                value={formData.message}
                                                onChange={handleInputChange}
                                            />
                                              {errors.message && <span className="text-danger">{errors.message}</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="text-center">
                                            <Button type="submit" variant="primary" className="btn-rounded btn-wh-200-45">
                                                Register Now
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                          
                        </Modal.Body>
                    </Modal>
                </Container>
            </section>

            <section className="you-are-not-alone-section">
                <Container>
                    {/* <div className="section-title text-center">
                        <h2>You Are Not Alone</h2>
                    </div> */}
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <Card className="card-not-alone">
                                <Card.Body>
                                    {/* <Card.Title>1 out of every 4 people has faced a</Card.Title> */}
                                    <h3>We are here for you!!</h3>
                                    {/* <Card.Text>Its okay to face a breakup</Card.Text> */}
                                    {/* <Button variant="secondary" type="button" className="btn-rounded btn-wh-160-45">You’re not alone!</Button> */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <Card className="card-not-alone">
                                <Card.Body>
                                    {/* <Card.Title>1 out of every 3 people is facing</Card.Title> */}
                                    <h3>We understand the 
                                        importance of a 
                                        judgement free zone, 
                                        where you can vent 
                                        out your feelings.</h3>
                                    {/* <Card.Text>Its okay to have a relationship issue.</Card.Text> */}
                                    {/* <Button variant="secondary" type="button" className="btn-rounded btn-wh-160-45">You’re not alone!</Button> */}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <Card className="card-not-alone">
                                <Card.Body>
                                    {/* <Card.Title>1 out of every 2 people has faced</Card.Title> */}
                                    <h3>And Get back to happiness</h3>
                                    {/* <Card.Text>Its okay to feel lonely.</Card.Text> */}
                                    {/* <Button variant="secondary" type="button" className="btn-rounded btn-wh-160-45">You’re not alone!</Button> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="about-us-section" id="about">
                <Container>
                    <Row >
                        <Col lg={7} md={12} sm={12}>
                            <div className="section-title text-start">
                                <h2>{aboutData.title}</h2>
                            </div>
                             <div className="ckEditor" dangerouslySetInnerHTML={{ __html: aboutData.description }}>
                             </div>
                        </Col>
                        <Col lg={5} md={12} sm={12}>
                            <div className="about-us-img">
                                 <img alt="Map"
                                    className="cover"
                                    src={aboutData.image}
                                /> 
                            </div>
                        </Col>
                    </Row>


                    <Row className='mt-2'>
                        <Col lg={12} md={12} sm={12}>
                            <div className="section-title text-start">
                                <h2>Emotional Support Platform - Emotional Touch</h2>
                            </div>
                            <div className='ckEditor'>
                            <p>
                             An Emotional Support Platform is an important support system. It gives a safe space for people looking for comfort and connection in their emotional lives. This platform plays a key role in promoting mental support. It provides resources and encourages open discussion. It becomes a lifeline for friends. It offers tools to deal with challenges, share experiences, and promote understanding. Through a supportive community, the platform becomes a symbol of empathy. It helps friends connect, grow, and navigate the complexities of emotions together.

                             </p>
                            </div>
                             
                        </Col>
                    </Row>

                    <Row >
                        <Col lg={12} md={12} sm={12}>
                            <div className="section-title text-start">
                                <h2>Are you across them?</h2>
                            </div>
                            <div className='ckEditor'>
                            <p>
                             Emotional support means being aware of your own feelings and others' feelings. It means having self-awareness and empathy. It includes being able to cope with life's challenges, have positive relationships, and make informed choices. Focusing on emotional wellness helps build mental resilience and a balanced, fulfilling life. It encourages people to try practices like mindfulness, self-reflection, and getting support when needed. This allows a whole-person approach to personal growth and happiness.
                            </p>
                            <p>Let's make emotional wellbeing a priority right now!</p>


                             </div>
                        </Col>
                    </Row>


                </Container>
            </section>

            <section className="listeners-section" id="listeners-sec">
                <Container>
                    <div className="section-title text-center">
                        <h2>Listeners</h2>
                    </div>
                    <Row>
                        {listenerData.listener_data.map((listener, index) => (
                            <Col lg={3} md={6} sm={6}>
                                <Card className="card-listeners">
                                    <Card.Body>
                                        <div className="card-profile">
                                            <figure className="figure-100 figure-circle">
                                                <img alt="profile-image-01"
                                                    className="cover"
                                                    src={listener.profile_photo.replace('https:/', 'https://')}
                                                />
                                            </figure>
                                            <span className="online1"></span>
                                        </div>
                                        <h3>{listener.full_name}</h3>
                                        <div className="tags-wrap"><span className="tags">My Story</span></div>
                                        <Card.Text> {listener.story.substring(0, 80) + '...'}</Card.Text>
                                        <Button variant="dark" type="button" href="/#footer-div" className="btn-rounded btn-wh-120-45">Talk Now</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))} 
                        
                        
                        <Col lg={12} md={12} sm={12}>
                            <Form.Group className="mt-4 mb-3 text-center">
                                <a  className="btn btn-primary btn-rounded btn-wh-200-45" href="/#footer-div">View More Listeners</a>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <section className="reviews-section">
                <Container>
                    <div className="section-title text-start">
                        <h2>Reviews</h2>
                    </div>

                    <Swiper
                        className="swiper-reviews"
                        modules={[Pagination]}
                        spaceBetween={30}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            767: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <Card className="card-reviews">
                                <Card.Body>
                                    <div className="card-profile-details">
                                        <div className="card-profile-rating d-flex mb-3">
                                            <figure className="figure-50 figure-circle mb-0 me-3">
                                                <img alt="profile-image-01"
                                                    className="cover"
                                                    src={require("../Assests/images/profile-image-01.png")}
                                                />
                                            </figure>
                                            <div className="profile-name">
                                                <h3>Jaoan Perkins</h3>
                                                <div className="rating-star">
                                                    <Rating
                                                        emptySymbol="far fa-star staroutline"
                                                        fullSymbol="fas fa-star medium starfill"
                                                        initialRating={3}
                                                        fractions={2}
                                                        readonly
                                                        onChange={(rate) => document.getElementById('lbl-rating').innerHTML = rate || ''}
                                                    />
                                                    <b id='lbl-rating'>3.0</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-date-time mb-3">
                                            <p>02-08-2023 <br></br><b>12:30 AM</b></p>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="card-reviews">
                                <Card.Body>
                                    <div className="card-profile-details">
                                        <div className="card-profile-rating d-flex mb-3">
                                            <figure className="figure-50 figure-circle mb-0 me-3">
                                                <img alt="profile-image-05"
                                                    className="cover"
                                                    src={require("../Assests/images/profile-image-05.png")}
                                                />
                                            </figure>
                                            <div className="profile-name">
                                                <h3>Frank Gratstt</h3>
                                                <div className="rating-star">
                                                    <Rating
                                                        emptySymbol="far fa-star staroutline"
                                                        fullSymbol="fas fa-star medium starfill"
                                                        initialRating={4}
                                                        fractions={2}
                                                        readonly
                                                        onChange={(rate) => document.getElementById('lbl-rating').innerHTML = rate || ''}
                                                    />
                                                    <b id='lbl-rating'>4.0</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-date-time mb-3">
                                            <p>02-08-2023 <br></br><b>12:30 AM</b></p>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        I was broken after my breakup and had no sign of hope. I had a word with one of the listeners who provided me with a family relationship issues and doing better in life.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="card-reviews">
                                <Card.Body>
                                    <div className="card-profile-details">
                                        <div className="card-profile-rating d-flex mb-3">
                                            <figure className="figure-50 figure-circle mb-0 me-3">
                                                <img alt="profile-image-10"
                                                    className="cover"
                                                    src={require("../Assests/images/profile-image-10.png")}
                                                />
                                            </figure>
                                            <div className="profile-name">
                                                <h3>Shweta Rai</h3>
                                                <div className="rating-star">
                                                    <Rating
                                                        emptySymbol="far fa-star staroutline"
                                                        fullSymbol="fas fa-star medium starfill"
                                                        initialRating={5}
                                                        fractions={2}
                                                        readonly
                                                        onChange={(rate) => document.getElementById('lbl-rating').innerHTML = rate || ''}
                                                    />
                                                    <b id='lbl-rating'>5.0</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-date-time mb-3">
                                            <p>02-08-2023 <br></br><b>12:30 AM</b></p>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        Being a Listener on Clarity is so relieving. It feels good to help people everyday. Apart from the money me feel that I have done something nice today 😊
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="card-reviews">
                                <Card.Body>
                                    <div className="card-profile-details">
                                        <div className="card-profile-rating d-flex mb-3">
                                            <figure className="figure-50 figure-circle mb-0 me-3">
                                                <img alt="profile-image-09"
                                                    className="cover"
                                                    src={require("../Assests/images/profile-image-09.png")}
                                                />
                                            </figure>
                                            <div className="profile-name">
                                                <h3>Amisha Chaudhary</h3>
                                                <div className="rating-star">
                                                    <Rating
                                                        emptySymbol="far fa-star staroutline"
                                                        fullSymbol="fas fa-star medium starfill"
                                                        initialRating={3}
                                                        fractions={2}
                                                        readonly
                                                        onChange={(rate) => document.getElementById('lbl-rating').innerHTML = rate || ''}
                                                    />
                                                    <b id='lbl-rating'>3</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-date-time mb-3">
                                            <p>02-08-2023 <br></br><b>12:30 AM</b></p>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </SwiperSlide>
                    </Swiper>
                </Container>
            </section> */}

            <section className="faqs-section" id="faqs">
                <Container>
                    <div className="section-title text-center">
                        <h2>FAQs</h2>
                    </div>
                    <Accordion defaultActiveKey="0" flush className="accordion-custom">
                        {faqsData.faqs_datails.map((faq, index) => (
                            <Accordion.Item eventKey={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                                <Accordion.Body>
                                    {faq.answer}
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}  
                    </Accordion>
                </Container>
            </section>

            
        </main>
    );
}




export default HomePageController;