import React from 'react'


// HTML
import HomePageController from '../Controller/HomePageController';
import SignupController from '../Controller/signupController';
import ContactusController from '../Controller/contactusController';
import TermsconditionController from '../Controller/termsconditionController';
import PrivacyPolicyController from '../Controller/PrivacyPolicyController';
import RefundPolicyController from '../Controller/RefundPolicyController';
import EmotionaltouchController from '../Controller/EmotionaltouchController';

const appRoutes = [
  {
    path: '/',
    exact: true,
    name: 'home',
    component: HomePageController,
    //roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  }, 
  {
    path: '/signup',
    exact: true,
    name: 'signup',
    component: SignupController,
    //roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  {
    path: '/contact-us',
    exact: true,
    name: 'contact-us',
    component: ContactusController,
    //roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  }, 
  {
    path: '/terms-condition',
    exact: true,
    name: 'terms-condition',
    component: TermsconditionController,
    //roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  }, 
  {
    path: '/privacy-policy',
    exact: true,
    name: 'privacy-policy',
    component: PrivacyPolicyController,
    //roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  {
    path: '/refund-policy',
    exact: true,
    name: 'refund-policy',
    component: RefundPolicyController,
  },
  {
    path: '/emotional-touch/:urlId',
    exact: true,
    name: 'emotional-touch',
    component: EmotionaltouchController,
  },
]

export { appRoutes, }
